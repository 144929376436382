<template>
  <div>
    <!-- tab栏 -->
    <!-- <van-sticky>
      <van-tabs @click="onClickOnlyOne"
        color="#fb4d3f"
        title-active-color="#fb4d3f"
        swipeable
        swipe-threshold="4">
        <van-tab v-for="item in TitleList"
          :key="item.AKId"
          :name="item.AKId"
          :title="item.Name">
        </van-tab>
      </van-tabs>
    </van-sticky> -->
    <van-sticky>
      <van-search
        v-model="listfrom.title"
        placeholder="请输入活动标题"
        shape="round"
        show-action
        @search="onSearch"
        @clear="onCancel"
      >
        <template #action>
          <div @click="onSearch">搜索</div>
        </template>
        <template #left-icon>
          <img
            src="http://jmylapi.zhsq.cloud//upload/files/2022/11/ZR2uIACzwrnj6968bb9baa4a2b20d6e27daf88a25a15.png"
            style="
              width: 16px;
              height: 16px;
              margin-right: 10px;
              margin-top: 5px;
            "
          />
        </template>
      </van-search>
    </van-sticky>
    <div class="information">
      <!-- 全部 -->
      <van-list
        v-model="loading"
        :finished="finished"
        :offset="100"
        finished-text="没有更多了"
        @load="onLoad"
        :immediate-check="false"
      >
        <div
          class="center"
          style="padding: 17px 6px"
          v-for="item in list"
          :key="item.index"
        >
          <div class="nian">
            {{ item.IssTime.split("T")[0].split("-")[0] }}年
          </div>
          <div
            style="
              position: relative;
              top: -8px;
              width: 50px;
              height: 5px;
              background: linear-gradient(to right, #d44f43, #fff);
            "
          ></div>

          <div>
            <div style="display: flex; margin-bottom: 10px; margin-top: 14px">
              <span class="yue">{{
                item.IssTime.split("T")[0].split("-")[1] +
                "/" +
                item.IssTime.split("T")[0].split("-")[2]
              }}</span>
              <span class="yuan"></span>
              <div class="title">{{ item.Title }}</div>
            </div>

            <div
              style="display: flex"
              @click="gotoUrl('/fivestar/wuXingXq/' + item.AEId + '-2')"
            >
              <div class="line"></div>
              <div
                style="
                  display: flex;
                  flex-direction: column;
                  align-items: baseline;
                "
              >
                <div class="content">
                  {{ item.Digest }}
                </div>
                <div class="img" ref="getHeight">
                  <img
                    v-if="item.Imgs != null && item.Imgs != ''"
                    :src="item.Imgs.split(',')[0]"
                    alt=""
                    style="min-height: 80px"
                  />
                  <img
                    v-else
                    src="http://alycommunity.anluoyun.cn/sx-jgz/2023/6/d230d06c-3c3.png"
                    alt=""
                    style="min-height: 80px"
                  />
                </div>
                <div class="recman">发布单位：{{ item.OrgName }}</div>
              </div>
            </div>
          </div>
        </div>

        <!-- <div
          @click="gotoXQ(item)"
          class="center borderClass"
          v-for="item in list"
          :key="item.index"
        >
          <div class="Content">
            <div
              style="display: inline-block; width: 33%"
              class="ContentA"
              ref="getHeight"
            >
              <img
                v-if="item.Imgs"
                style="border-radius: 5px; min-height: 85px"
                :src="item.Imgs.split(',')[0]"
                alt=""
              />
              <img
                v-else
                style="border-radius: 5px; min-height: 85px"
                src="https://lyxnyapi.dexian.ren//upload/images/2022/7/d0e8c771-cd6.png"
              />
            </div>
            <div style="display: inline-block; width: 63%" class="ContentB">
              <div class="centerTitle van-multi-ellipsis--l2">
                {{ item.Title }}
              </div>
              <span
                class="centertime van-multi-ellipsis--l2"
                style="margin: 5px 0px"
                >{{ item.Digest }}</span
              >
            </div>

            <span
              class="centertime"
              style="
                float: right;
                margin-top: -15px;
                color: #999999;
                font-size: 11px;
              "
              >{{ item.IssTime | timeFilter }}</span
            >
          </div>
        </div> -->
      </van-list>

      <!-- 没有更多数据了 -->
      <!-- <div v-if="hidden"
        class="hidden">───── 没有更多数据了 ─────</div> -->
    </div>
  </div>
</template>
<script>
import { setOpenId, getOCode } from "@/utils/auth";
import { WxGetPbActElegantPage, WeGetPBActivityPage } from "@/api/RealInfo";
export default {
  data() {
    return {
      akId: 0, //tab栏
      activeName: "",
      list: [], //资讯列表
      listfrom: {
        page: 1,
        limit: 10,
        pbAKFlag: 5,
        title: "",
        orgCode: undefined,
      }, //查询传参
      hidden: true, //没有更多数据了
      TitleList: [],
      loading: false,
      finished: false,
    };
  },
  filters: {
    timeFilter(value) {
      if (value != undefined) {
        // let gstime = value.replace('T', ' ')
        let gstime = value.substring(0, 10);
        return gstime;
      }
    },
  },
  methods: {
    gotoUrl(path) {
      // console.log(row);
      // this.$router.push({
      //   name: "wuXingXq",
      //   query: { Id: row.AEId, add: 2 },
      // });
      if (window.__wxjs_environment === "miniprogram") {
        window.wx.miniProgram.navigateTo({
          url: "../public/goto?path=" + path,
        });
      } else {
        this.$router.push(path);
      }
    },
    // 活动分类
    // getPBActivityList: function () {
    //   WeGetPBActivityPage({ PAKind: 2 }).then((res) => {
    //     this.TitleList = res.data.data;
    //     if (this.TitleList[0].Children != "0") {
    //       for (let i = 0; i < this.TitleList.length; i++) {
    //         const element = this.TitleList[i];
    //         if (element.Name == "支部过硬星") {
    //           this.TitleList = element.Children;
    //           this.listfrom.akId = element.Children[0].AKId;
    //           // this.listfrom.akId = element.AKId
    //         }
    //       }
    //     } else {
    //     }
    //     this.getList();
    //   });
    // },
    // onSearch
    onSearch() {
      this.list = [];
      this.listfrom.page = 1;
      // this.listfrom.title = val;
      this.getList();
    },
    onCancel() {
      this.list = [];
      this.listfrom.page = 1;
      this.listfrom.title = "";
      this.getList();
    },
    // 下拉刷新
    onLoad() {
      this.loading = true;
      this.listfrom.page = this.listfrom.page + 1;
      this.getList();
    },
    // 获取分页列表
    getList() {
      // this.loading = true
      WxGetPbActElegantPage(this.listfrom)
        .then((res) => {
          this.loading = false;
          if (res.data.code == 0) {
            let rows = res.data.data; //请求返回当页的列表
            // 加载状态结束

            if (rows == null || rows.length === 0) {
              // 加载结束
              console.log("结束");
              this.finished = true;
              return;
            }
            // 将新数据与老数据进行合并
            this.list = this.list.concat(rows);
            //如果列表数据条数>=总条数，不再触发滚动加载
            if (this.list.length >= res.data.count) {
              this.finished = true;
            }

            // this.loading = false
            // if (res.data.count == 0) {
            //   this.list = {};
            // } else {
            //   this.list = res.data.data;
            //   for (var i = 0; i < this.list.length; i++) {
            //     if (this.list[i].AImgUrl == "") {
            //       this.list[i].AImgUrl =
            //         "https://lyxnyapi.dexian.ren//upload/images/2022/7/d0e8c771-cd6.png";
            //     }
            //   }
            // }
          } else {
            this.finished = true;
          }
        })
        .catch(() => {});
    },
    topClick: function (name, title) {
      for (let index = 0; index < this.TitleList.length; index++) {
        const element = this.TitleList[index];
        if (element.Children.length != "0") {
          if (element.AKId == name) {
            this.listfrom.akId = element.Children[0].AKId;
            this.activeName = element.Children[0].AKId;
            this.getList();
          } else {
            this.listfrom.akId = element.AKId;
            this.getList();
          }
        }
      }
    },
    // tab点击切换事件
    onClick(name, title) {
      this.list = [];
      this.listfrom.akId = name;
      this.listfrom.page = 1;
      this.getList();
    },
    onClickOnlyOne(name, title) {
      this.list = [];
      this.listfrom.akId = name;
      this.listfrom.page = 1;
      this.getList();
    },
  },
  mounted() {
    if (this.$route.query["openid"]) {
      setOpenId(this.$route.query["openid"]);
    }
    if (getOCode() != undefined) {
      if (JSON.parse(getOCode())) {
        console.log(JSON.parse(getOCode()), "JSON.parse(getOCode())");
        this.listfrom.orgCode = JSON.parse(getOCode()).orgCode;
      } else {
        this.listfrom.orgCode = undefined;
      }
    }
    this.getList();
  },
};
</script>
<style scoped>
.hidden {
  text-align: center;
  font-size: 12px;
  color: #ccc;
  margin: 10px 0px 25px 0px;
}
.information {
  width: 95%;
  margin: auto;
}
.borderClass {
  padding: 0;
  box-shadow: 0px 5px 17px 1px rgb(0 0 0 / 7%);
}
.borderClass .Content {
  padding: 8px;
  margin-top: 10px;
}

.nian {
  position: relative;
  z-index: 1;
  font-size: 15px;
  font-family: PingFang SC;
  font-weight: bold;
  color: #231815;
}

.yuan {
  width: 7px;
  height: 7px;
  background: rgb(252, 72, 57);
  display: inline-block;
  border-radius: 50%;
  vertical-align: middle;
  margin: 7px 0;
}
.yue {
  font-size: 14px;
  font-family: PingFang SC;
  font-weight: 400;
  color: #231815;
  margin-right: 8px;
}

.line {
  width: 1px;
  height: 198px;
  background: #e8e8e8;
  margin-left: 52px;
  margin-right: 24px;
}

.title {
  margin-left: 21px;
  font-size: 15px;
  font-family: PingFang SC;
  font-weight: bold;
  color: #231815;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.content {
  height: 63px;
  font-size: 14px;
  font-family: PingFang SC;
  color: #231815;
  font-weight: normal;
  margin-top: 6px;
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

.img img {
  border-radius: 11px;
  height: 74px;
  width: 74px;
  margin-top: 12px;
}

.recman {
  margin-top: 10px;
  font-size: 13px;
  font-family: PingFang SC;
  font-weight: 400;
  color: #999999;
}

/deep/ .van-tab {
  font-size: 15px;
  font-weight: 400;
  color: #666666;
  /* color: #000000; */
  /* font-family: PingFangSC; */
}

/deep/ .van-tab--active {
  font-weight: bold;
  font-size: 15px;
  color: #fc4839;
}
</style>